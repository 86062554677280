<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from 'axios';
const moment = require('moment');
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import _ from "lodash";
import Switches from "vue-switches";

/**
 * Elements component
 */
export default {
  page: {
    title: "Customer List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, DatePicker, Switches },
  data() {
    return {
      title: "List Pelanggan",
      items: [
      ],
      userId: null,
      submittedForm: false,
      userName: null,
      hasData: false,
      settingId: '',
      data: {
        category: '',
        problems: '',

        stage: {
          code: 'ticketSubmitted',
          createdAt: moment().format(),
          createdBy: this.$store.state.authfack.user.username
        },
        status: 'open',
        priority: '',
        customerId: '',
        customerProduct: '',
        deadlineInstallation: '',
        assignmentGroup: [],
        isCustomer: false
      },
      choice: {
        customerProduct: {
          code: '',
          label: 'Pilih'
        },
        category: {
          code: '',
          label: 'Pilih'
        },
        priority: {
          code: '',
          label: 'Pilih'
        }
      },
      categoryList: [
        {
          label: 'Internet',
          code: 'internet'
        },
        {
          label: 'Administration',
          code: 'admin'
        },
        {
          label: 'Installation',
          code: 'installation'
        }
      ],
      priorityList: [
        {
          label: 'Low',
          code: 'low'
        },
        {
          label: 'High',
          code: 'high'
        }
      ],
      listCustomer: [],
      listProduct: [],
      kategoriAltInstal: false,
      runTroubleShoot: false,
      troubleShoot: false,
      cardTroubleShooting: false,
      selectedDateTime: {
        date: null,
        time: null, // Untuk menyimpan waktu yang dipilih
      },
      compoAcordion: {
        acordion1: false,
        acordion2: false,
        acordion3: false,
        acordion4: false,
        acordion5: false,
      },
      showFilters: false,
      isSidebarOpen: false,
      openTime: false,

      StateTroubleShootValue: {
        oltRx: null,
        onuRx: null,
        onuSignal: null,
        onuIP: null,
        onuUptime: null,
        onuState: null,
        result: null,
        code: null,
        checkingPayment: null,
        ticketId: null,
        stage_id: null
      },
      stateTroubleShoot: {
        oltRx: null,
        onuRx: null,
        signal: null,
        onuIP: null,
        upTime: null,
        status: null,
        result: null
      },

      loadingStateTroubleShoot: {
        oltRx: true,
        onuRx: true,
        signal: true,
        onuIP: true,
        upTime: true,
        status: true,
        result: true
      },
      fieldsDatakategori: [

        {
          label: "Client Grup",
          key: "clientGroup",
          sortable: true,
          thStyle: { width: "14%" },
        },
        {
          label: "Nama Pelanggan",
          key: "name",
          sortable: true,
          thStyle: { width: "19%" },
        },
        {
          label: "Order Id",
          key: "orderId",
          sortable: true,
          thStyle: { width: "10%" },
        },
        {
          label: "No HP",
          key: "phonenumber",
          sortable: true,
          thStyle: { width: "8%" },
        },
        {
          label: "Product",
          key: "products",
          sortable: true,
          thStyle: { width: "15%" },

        },


        {
          label: "Status Invoice",
          key: "status",
          sortable: true,
          thStyle: { width: "15%" },
          formatter: "formatStatusInvoice",
        },
        {
          label: "Registrasi Pada",
          key: "createdAt",
          sortable: true,
          thStyle: { width: "14%" },
          formatter: (tanggal) =>
            moment(tanggal, "DD-MM-YYYY HH:mmZ", true).isValid()
              ? moment(tanggal, "DD-MM-YYYY HH:mmZ").format("DD-MM-YYYY")
              : "Invalid Date"
        },
      ],
      // kategoriData: {
      //   kategoriAdminData: null,
      //   kategoriAdminDataHigh: null,
      //   kategoriAdminDataLow: null,
      //   kategoriInstalationData: null,
      //   kategoriInstalationDataHigh: null,
      //   kategoriInstalationDataLow: null,
      //   kategoriInternetData: null,
      //   kategoriInternetDataHigh: null,
      //   kategoriInternetDataLow: null
      // },
      customerData: [],
      kategoriData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filterDateTime: null,
      selectedPetugas: [],
      selectedPetugasCount: 1,
      holderIdPetugas: null,
      optionsPetugas: null,
      holderUpdateAssigment: null,
      paginationCountSkip: 0,
      advFilterQuery: {
        clientGrup: null,
        product: null,
        tanggalRegis: null,
        dateEnd: null,
        statusProduk: null
      },
      advFilterRange: null,
      advFilterHolder: {
        advFilterClientGroup: [],
        advFilterProduct: [],
        advFilterTanggal: [],
        advFilterStatus: []
      },
      advFilterParamOn: false,
      regexSearchParamOn: false,
      regexHolder: "",
      regexIdentifierSearch: false,
      holderDataStatusInvoice: [],
      holderDataStatusTicket: [],
      holderCustomerId: null,
      modalPopupBadge: false,
      accordionState: {},
      jumlahData: 0,
      paginationRequest: false,
      isCheckedRange: false,
      sidebarWidth: "",
      totalRowsCount:0,
      filterRequest:false

    };
  },
  validations: {
    data: {
      customerProduct: {
        required
      },
      priority: {
        required
      },
      category: {
        required
      },
      problems: {
        required
      }
    }
  },
  // async created() {

  // },

  sockets: {
    connect: function () {
      console.log('socket connected ticket')
    },




  },


  async created() {
    

    console.log(sessionStorage);
    const savedDataFilters = sessionStorage.getItem("savedDataFilters");
    const savedFilters = sessionStorage.getItem("savedFilters");
    if (savedDataFilters){
        if (savedDataFilters) {
          try {
            this.customerData = JSON.parse(savedDataFilters); 
            this.jumlahData = this.customerData.length
            this.advFilterQuery = JSON.parse(savedFilters)
            this.showFilters= true,
            this.isSidebarOpen= true,
            this.totalRowsCount =  sessionStorage.getItem("savedtotalRowsCount")
            this.currentPage =  sessionStorage.getItem("savedCurrentPage")
            this.compoAcordion = JSON.parse(sessionStorage.getItem("savedAccordion")) || {};
            this.paginationCountSkip = JSON.parse(sessionStorage.getItem("savedSkippedData"))

            this.advFilterParamOn = true
          } catch (error) {
            console.error("Gagal memparsing data:", error);
            this.customerData = []; 
          }
        }
    }
    else {
      await this.getCustomerData()
    }
    
    console.log('this.$store.state.authfack.user?.clientgrup', this.$store.state.authfack.user.id);

    const clientGrup = this.$store.state.authfack.user?.clientgrup || []
    this.advFilterHolder.advFilterClientGroup = clientGrup.map(group => ({
      text: group.code,
      value: group.code
    }))


    await axios.get(process.env.VUE_APP_URL_FEATHERS + 'customer-list?clientGroupList=' + this.$store.state.authfack.user.id)
      .then(response => {
        this.advFilterHolder.advFilterProduct = response.data.map(product => ({
          text: product.namaProduct,
          value: product.id
        }))
      })
    console.log('this.advFilterHolder.advFilterProduct', this.advFilterHolder.advFilterProduct);

    

    const loggeduser = localStorage.getItem('user');
    const jsonUser = JSON.parse(loggeduser)
    this.userName = jsonUser.username
    await this.getDataPetugas()
    this.userId = await axios.get(process.env.VUE_APP_URL_FEATHERS + 'login?username=' + jsonUser.username)
      .then(response => {
        return response.data.data[0]._id
      })



    // await axios.get(process.env.VUE_APP_URL_FEATHERS + 'user-setting?$populate=user')
    //         .then(response => {
    //           if (response.data.total != 0){
    //             this.settingId = response.data.data[0]._id
    //             this.hasData = true
    //             this.data = response.data.data[0]
    //           }
    //       }) 
  },
  beforeDestroy() {
  document.removeEventListener("contextmenu", this.disableRightClick);
},
  watch: {
    'choice.customerProduct': {
      //immediate: true,
      handler(newValue) {
        if (newValue === 0 || newValue.code != '') {
          //  console.log('VAL', newValue)
          this.data.customerProduct = newValue.code
        }
      }
    },
    
    'choice.category': {
      //immediate: true,
      handler(newValue) {

        if (newValue.code == "installation") {
          this.kategoriAltInstal = true
        }
        else {
          this.kategoriAltInstal = false
        }
        if (newValue === 0 || newValue.code != '') {
          //  console.log('VAL', newValue)
          this.data.category = newValue.code


          if (this.data.category != "installation") {
            this.troubleShoot = true
            // this.runTroubleShoot = true
          }
          else {
            this.troubleShoot = false
            // this.runTroubleShoot = true
          }

        }
        this.choice.priority = ''
        // this.runTroubleShoot = false
      }
    },
    'choice.priority': {
      //  immediate: true,

      handler(newValue) {

        if (newValue === 0 || newValue.code != '') {
          //  console.log('VAL', newValue)

          this.data.priority = newValue.code


        }

      }
    },

    'selectedDateTime': {
      deep: true,
      handler(value) {
        if (value.time != null && value.date != null) {

          let dateString = `${value.date} ${value.time}`;
          const datetime = moment(dateString).format('YYYY-MM-DD HH:mm:ss')
          this.data.deadlineInstallation = datetime
        }

      }
    },
    'selectedPetugas': {
      deep: true,
      handler(value) {
        // Pastikan this.holderSelectedIdPetugas adalah array yang sesuai
        if (!Array.isArray(this.holderSelectedIdPetugas)) {
          this.holderSelectedIdPetugas = [];

        }


        if (this.kategoriAltInstal == true) {
          this.runTroubleShoot = true
        }

        for (let index = 0; index < value.length; index++) {
          for (let index2 = 0; index2 < this.optionsPetugas.length; index2++) {
            if (this.optionsPetugas[index2] === value[index]) {
              // Pastikan array holderSelectedIdPetugas memiliki ukuran yang sesuai
              this.holderSelectedIdPetugas[index] = this.holderIdPetugas[index2];
            }
          }
        }

      }
    },
    'currentPage': {

      handler(value) {

        let cek = Math.ceil(this.jumlahData / this.perPage)
        if (cek === value) {
          this.paginationRequest = true
        }
        console.log('page', value, cek);
      }
    },
    'perPage': {

     async handler(value) {

      console.log('this.customerData.length',this.customerData.length,value);
      
        if (this.customerData.length < value){
          console.log('sini');
         await this.getCustomerData()
        }
       
        console.log('page', value);
      }
    },

  
    


   

  },
  computed: {
    startRow() {
      return (this.currentPage - 1) * this.perPage + 1;
    },
    endRow() {
      let lastItem = this.currentPage * this.perPage;
      return lastItem > this.totalRowsCount ? this.totalRowsCount : lastItem;
    },
  },
  methods: {
   
    async getCustomerData() {
      let loader = this.$loading.show({
      loader: 'spinner',
      color: '#ff5024'
    });
      this.customerData = null
      this.regexIdentifierSearch = false
      this.regexHolder = ""
      let pageCount = this.perPage + 1
      let url = "customer-list?newTable=true&$skip=" + this.paginationCountSkip + "&$limit=" + pageCount
      this.customerData = await axios.get(process.env.VUE_APP_URL_FEATHERS + url).then(response => {
      
        
        this.totalRowsCount =  response.data.total
        this.jumlahData = response.data.data.length
        this.currentPage= 1
        if (this.paginationCountSkip === 0){
          this.paginationCountSkip = pageCount
        }
        return response.data.data

      })
      console.log('this.customerData', this.customerData);
      loader.hide()
    },


    toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
    this.$nextTick(() => {
      const sidebar = this.$refs.sidebar.$el;
      if (sidebar) {
        this.sidebarWidth = sidebar.offsetWidth + "px"; // Ambil lebar sidebar
      }
    });
  },
    formatStatusProducts(products) {

      if (!Array.isArray(products)) return "";

      const activeProducts = products.filter((product) => product.status === "active");
      const inactiveProducts = products.filter((product) => product.status !== "active" && product.status !== "suspend");
      const suspendProducts = products.filter((product) => product.status === "suspend");

      let badges = "";

      if (activeProducts.length > 0) {

       
          badges += `<span class="badge bg-success me-2 pt-1 font-size-12" data-type="active" style="cursor: pointer;">Active: ${activeProducts.length}</span>
    `
        
      }

      if (inactiveProducts.length > 0) {
        badges += `<span class="badge bg-danger me-2 pt-1 font-size-12" data-type="inactive" style="cursor: pointer;" disabled >Inactive</span>`;
      }

      if (suspendProducts.length > 0) {

        badges += `<span class="badge bg-danger font-size-12" data-type="suspend" style="cursor: pointer;">Suspend: ${suspendProducts.length}</span>`;
      }

      return badges;
    },
    formatStatusOrderId(products) {
      
      if (!Array.isArray(products)) return "";
      return products[0].orderId
      // const activeProducts = products.filter((product) => product.status === "active");
      // const inactiveProducts = products.filter((product) => product.status !== "active" && product.status !== "suspend");
      // const suspendProducts = products.filter((product) => product.status === "suspend");

      // let badges = "";

      // if (activeProducts.length > 0) {

      //   if (activeProducts.length > 1){
      //     for (let index = 0; index < activeProducts.length; index++) {
      //       badges += `<span class="badge bg-success me-1 font-size-12" data-type="active" style="cursor: pointer;">Active: ${activeProducts[index].productName}</span>`;
      //     }
      //   }
      //   else{
      //   badges += `<span class="badge bg-success me-2 pt-1 font-size-12" data-type="active" style="cursor: pointer;">Active: ${activeProducts[0].productName}</span>
      //   `;
      // }
      // }

      // if (inactiveProducts.length > 0) {
      //   badges += `<span class="badge bg-danger me-2 pt-1 font-size-12" data-type="inactive" style="cursor: pointer;" disabled >Inactive</span>`;
      // }

      // if (suspendProducts.length > 0) {

      //   badges += `<span class="badge bg-danger font-size-12" data-type="suspend" style="cursor: pointer;">Suspend: ${suspendProducts[0].productName}</span>`;
      // }

      // return badges;
    },
    formatStatusInvoice(products) {




      if (!Array.isArray(products)) return "";

      const activeProducts = products.filter((product) => product.status === "active");
      const inactiveProducts = products.filter((product) => product.status !== "active" && product.status !== "suspend");
      const suspendProducts = products.filter((product) => product.status === "suspend");

      let badges = "";

      if (activeProducts.length > 0) {
          badges += `<span class="badge bg-success me-2 pt-1 font-size-13" data-type="active" style="cursor: pointer;">Settelment : ${activeProducts.length}</span>`
        
      }

      if (inactiveProducts.length > 0) {
        badges += `<span class="badge bg-danger me-2 pt-1" data-type="inactive" style="cursor: pointer;" disabled >Inactive : ${activeProducts.length}</span>`;
      }

      if (suspendProducts.length > 0) {
        
        badges += `<span class="badge bg-warning font-size-13" data-type="suspend" style="cursor: pointer;">Pending ${suspendProducts.length}</span>`;
      }
      return badges;
    },

    toggleAccordion(index) {
      this.$set(this.accordionState, index, !this.accordionState[index]);
    },

    formatStatusTicket(ticket) {
      return {
        countTicket: ticket[0].totalTicket || 0,
        disabled: ticket[0].totalTicket === 0,
      };

    },


    viewInvoice() {

      const url = `/customer/${this.holderCustomerId}?index=1`;
      this.$refs['modal-invoice'].hide()
      window.open(url, '_blank');

    },
    viewTicket() {

      const url = `/customer/${this.holderCustomerId}?index=2`;
      this.$refs['modal-ticket'].hide()
      window.open(url, '_blank');

    },
    showTicketDetail(ticketId) {
      const url = `/ticket/ticketDetail?id=${ticketId.id}`;
      window.open(url, '_blank');
    },

    async refresh() {
      let pageCount = this.perPage + 1
      let url = "customer-list?$skip=" + this.paginationCountSkip + "&$limit=" + pageCount
      this.customerData = await axios.get(process.env.VUE_APP_URL_FEATHERS + url).then(response => {
        return response.data

      })
    },
    maskPhoneNumber(number) {
    if (!number) return 'N/A'; 
    const numStr = number.toString();
    
    if (numStr.length < 8) return number;
    
    return `${numStr.slice(0, 3)}*****${numStr.slice(-5)}`;
  },

    async paginateRequest() {
      let pageCount = 0
      pageCount = this.perPage + 1
      console.log('cobaaa');
      await this.$nextTick();
      console.log('this.advFilterParamOn',this.advFilterParamOn);
      
          
        if (this.advFilterParamOn === true) {

          console.log('current Page', this.currentPage);
          let cek = Math.ceil(this.jumlahData / this.perPage)
          sessionStorage.setItem("savedCurrentPage", JSON.stringify(this.currentPage));
          if (cek === this.currentPage){
            let loader = this.$loading.show({
            container: null,
            canCancel: false,
            color: '#1976D2'
          });

          console.log('adv request paginati0on');
          
          
          await this.filterTicket()
          
          loader.hide()
          }
        }
        else {

          let cek = Math.ceil(this.jumlahData / this.perPage)
          console.log('cek',cek,this.currentPage);
           if (cek === this.currentPage){
            let loader = this.$loading.show({
            container: null,
            canCancel: false,
            color: '#1976D2'
          });
          this.paginationCountSkip = 0

         this.paginationCountSkip = this.currentPage * this.perPage
          let url = "customer-list?newTable=true&$skip=" + this.paginationCountSkip + "&$limit=" + pageCount

        
          await axios.get(process.env.VUE_APP_URL_FEATHERS + url).then(response => {
           
            // this.customerData = this.customerData.concat(response.data.data)
            console.log('before',response);
            this.totalRowsCount = response.data.total
            this.customerData =  this.customerData.concat(response.data.data)
            console.log('this.customerData',this.customerData);
            
            this.jumlahData = this.customerData.length
            return this.customerData
          })
          loader.hide()
           }

          
          
        }
      
     
      if (this.advFilterParamOn === false) {
        this.skipCount()
      }



      // console.log("paginationCountSkip",this.paginationCountSkip);

    },
    async getCustomer(loading, search, vm) {
      vm.listCustomer = await axios.get(process.env.VUE_APP_URL_FEATHERS + 'customer-data?searchCustomer=' + search)
        .then(response => {

          loading(false);
          return response.data
        })
    },
    handleChange(value, type) {

      if (type === 'second') {
        this.openTime = false;
      }
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.getCustomer(loading, search, this);
      }
    },
    async setSelectedCustomer(value) {
      this.data.customerId = value.id
      this.listProduct = await axios.get(process.env.VUE_APP_URL_FEATHERS + 'costumer-products?customerIdTicket=' + value.id)
        .then(response => {
          return response.data.map(function (val) {
            return {
              label: val.orderId,
              code: val.id
            }
          })
        })
    },
    async setSelectedProduct(value) {
      // console.log('prodyc', value)
      this.data.customerProduct = value.code
    },
    rows() {
      this.jumlahData = this.customerData.length
      return this.totalRowsCount
    },
    async submit() {

      this.data.assignmentGroup = this.holderSelectedIdPetugas

      this.submittedForm = true;
      this.$v.data.$touch();
      if (this.$v.data.$invalid) {

        return;
      } else {

        let loader = this.$loading.show({
          // Optional parameters
          container: null,
          canCancel: false,
          color: '#1976D2'
        });



        await axios.post(process.env.VUE_APP_URL_FEATHERS + 'ticket', this.data)
          // eslint-disable-next-line no-unused-vars
          .then(async response => {
            this.StateTroubleShootValue.ticketId = response.data.ticketNo
            this.StateTroubleShootValue.code = response.data.stage.code
            this.StateTroubleShootValue.stage_id = response.data.stage[0].createdAt
            if (_.has(this.cardOptionResult, 'checkingPayment')) {
              this.StateTroubleShootValue.checkingPayment = this.cardOptionResult.checkingPayment
            }



            if (this.data.category != "installation") {

              await axios.post(process.env.VUE_APP_URL_FEATHERS + 'stage-detail-troubleshot', this.StateTroubleShootValue).then(res2 => {
                return res2;
              })
            }

            Swal.fire("Sukses!", "Data berhasil disimpan", "success");
            this.$router.push({ path: '/ticket' })
            this.$refs['modal-tambah-ticket'].hide()
          }).catch(error => {
            loader.hide()
            Swal.fire("Gagal!", "Data gagal disimpan, " + error.message, "error");
          })
        loader.hide()
      }
    },
    handleBadgeClick(products, event, index, data) {

      console.log('dataaa', products, event, index, data);
      console.log(products[0].status);
      if (!products.length > 1){
        if (products[0].status === "pending") {
        return;
      }
      }
     
      this.holderCustomerId = data.item._id
      
      
      const badge = event.target.closest("[data-type]"); 
      if (!badge) {
        console.warn("Klik bukan pada elemen badge.");
        return;
      }

      const type = badge.getAttribute("data-type"); // Ambil atribut 'data-type'
      if (!type) {
        console.warn("Badge tidak memiliki atribut data-type.");
        return;
      }

      console.log('badge',badge);
      
      // Filter produk berdasarkan tipe badge
      let filteredProducts = [];
      if (type === "active") {
        filteredProducts = products.filter((product) => product.status === "active");
      } else if (type === "inactive") {
        filteredProducts = products.filter((product) => product.status !== "active" && product.status !== "suspend");
      } else if (type === "suspend") {
        filteredProducts = products.filter((product) => product.status === "suspend");
      }

      console.log(`Badge '${type}' diklik. Data Produk:`, filteredProducts);

      this.modalPopupBadge = false
      this.showModalWithProducts(type, filteredProducts, index);
    },
    handleBadgeClickTicket(ticket, index) {
      let loader = this.$loading.show({
        loader: 'spinner',
        color: '#ff5024'
      });


      console.log('dataaa', ticket, index);
      if (this.formatStatusTicket(ticket.ticket).disabled) {
        return; // Jangan lakukan apa pun jika disabled
      }
      this.holderDataStatusTicket = []
      this.holderDataStatusTicket = ticket.ticket
      this.holderCustomerId = ticket.customerId
      this.$refs['modal-ticket'].show()
      loader.hide()

    },

    async showModalWithProducts(type, products, index) {
      let loader = this.$loading.show({
        loader: 'spinner',
        color: '#ff5024'
      });


      this.holderDataStatusInvoice = []; // Reset data sebelum digunakan


      for (let product of products) {
        let dataInvoices = await axios
          .get(process.env.VUE_APP_URL_FEATHERS + 'costumer-invoices?orderId=' + product.orderId)
          .then(response => response.data);

        if (Array.isArray(dataInvoices)) {
          const topFiveData = dataInvoices.slice(0, 5); // Ambil 5 data teratas
          console.log('topFiveData', topFiveData);

          this.holderDataStatusInvoice.push({
            orderId: product.orderId,
            productsName: topFiveData[0].productName,
            bulan: topFiveData[0].bulan,
            invoices: topFiveData, // Simpan data invoice di dalam "invoices"
          });
        } else {
          console.error("Unexpected response format: dataInvoices is not an array");
        }
      }

      console.log('holderDataStatusInvoice:', this.holderDataStatusInvoice);

      // Simpan tipe dan index yang dipilih
      this.selectedProducts = products;
      this.selectedType = type;
      this.selectedIndex = index;

      // Tampilkan modal
      this.modalPopupBadge = true;
      this.$refs['modal-invoice'].show();
      loader.hide()
    },
    getTicketStageText(stage) {
      const stageMapping = {
        ticketSubmitted: "Tiket Dibuat",
        ticketOnProgress: "Dalam Pengerjaan",
        ticketProgressDone: "Pengerjaan Selesai",
        ticketDone: "Tiket Selesai",
      };
      return stageMapping[stage] || "N/A";
    },



    async runTroubleShootMethod(row, item) {

      this.runTroubleShoot = true
      this.resetLoadingState()
      this.data.assignmentGroup = this.holderSelectedIdPetugas

      if (row != null) {

        await axios.get(process.env.VUE_APP_URL_FEATHERS + 'stage-detail-troubleshot?troubleshot=' + row.orderId).then(async response => {
          this.resetLoadingState()

          this.cardOptionResult = response.data
          this.StateTroubleShootValue.checkingPayment = this.cardOptionResult.checkingPayment
          this.StateTroubleShootValue.onuIP = this.cardOptionResult.onuIP
          this.StateTroubleShootValue.ticketId = row.noTicket
          this.StateTroubleShootValue.code = item.stageData.code
          this.StateTroubleShootValue.rating = response.data[0].rating
          this.cardOptionResult.ticketId = row.noTicket
          this.cardOptionResult.code = item.stageData.code
          this.cardOptionResult.stage_id = item.stageData._id
          if (row.stage.length >= 1) {
            this.StateTroubleShootValue.stage_id = item.stageData._id

          }
          else {
            this.StateTroubleShootValue.stage_id = item.stageData._id

          }
          await this.showLoadingOverlay()
          this.holderRowData = row
          return response
        })
      }
      //this.runTroubleShoot = false
      else {
        this.runTroubleShoot = true
        this.troubleShootLoading = true
        this.cardTroubleShooting = true
        await axios.get(process.env.VUE_APP_URL_FEATHERS + 'ticket-analytic?troubleshot=' + this.data.customerProduct).then(response => {
          this.cardOptionResult = response.data

          this.StateTroubleShootValue.checkingPayment = this.cardOptionResult.checkingPayment
          this.StateTroubleShootValue.onuIP = this.cardOptionResult.onuIP
          this.StateTroubleShootValue.rating = this.cardOptionResult.rating
          this.showLoadingOverlay()
          return response
        })
      }
      //

    },
    async showLoadingOverlay() {
      this.runTroubleShoot = false

      await setTimeout(() => {

      }, 0);
      // Simulate some asynchronous operation, e.g., fetching data
      setTimeout(() => {
        this.loadingStateTroubleShoot.signal = false

        this.StateTroubleShootValue.onuSignal = this.cardOptionResult.onuSignal
        if (this.StateTroubleShootValue.onuSignal == "baik") {
          this.stateTroubleShoot.signal = true
        }
        else {
          this.stateTroubleShoot.signal = false
        }
      }, 0); // Simulating a 2-second loading delay
      setTimeout(() => {

        this.loadingStateTroubleShoot.oltRx = false;

        this.StateTroubleShootValue.oltRx = this.cardOptionResult.oltRx
        if (this.StateTroubleShootValue.onuSignal == "baik") {
          this.stateTroubleShoot.oltRx = true
        }
        else {
          this.stateTroubleShoot.oltRx = false
        }


      }, 0); // Simulating a 2-second loading delay
      setTimeout(() => {
        this.loadingStateTroubleShoot.onuRx = false;
        this.StateTroubleShootValue.onuRx = this.cardOptionResult.onuRx

        if (this.StateTroubleShootValue.onuSignal == "baik") {
          this.stateTroubleShoot.onuRx = true
        }
        else {
          this.stateTroubleShoot.onuRx = false
        }


      }, 0); // Simulating a 2-second loading delay
      setTimeout(() => {


        this.loadingStateTroubleShoot.status = false;
        this.StateTroubleShootValue.onuState = this.cardOptionResult.onuState

        if (this.StateTroubleShootValue.onuState == "working") {
          this.stateTroubleShoot.status = true
        }
        else {
          this.stateTroubleShoot.status = false
        }

      }, 0); // Simulating a 2-second loading delay
      setTimeout(() => {
        this.loadingStateTroubleShoot.upTime = false;
        this.StateTroubleShootValue.onuUptime = this.cardOptionResult.onuUptime
        if (this.StateTroubleShootValue.onuUptime != null) {
          this.stateTroubleShoot.upTime = true
        }
        else {
          this.stateTroubleShoot.upTime = false
        }

      }, 0); // Simulating a 2-second loading delay
      setTimeout(() => {
        this.loadingStateTroubleShoot.onuIP = false;
        this.StateTroubleShootValue.onuIP = this.cardOptionResult.onuIP
        if (this.StateTroubleShootValue.onuIP != null) {
          this.stateTroubleShoot.onuIP = true
        }
        else {
          this.stateTroubleShoot.onuIP = false
        }

      }, 0); // Simulating a 2-second loading delay
      setTimeout(() => {
        this.loadingStateTroubleShoot.status = false;
        this.StateTroubleShootValue.result = this.cardOptionResult.result
        this.runTroubleShoot = true
      }, 0); // Simulating a 2-second loading delay

    },
    handleRowClicked(item) {
      let loader = this.$loading.show({
        loader: 'spinner',
        color: '#ff5024'
      });
        console.log('item', item);

        let id = item._id;
        this.$router.push({ path: `/customer/${id}`, query: { index: 0 } });
        loader.hide()
      },

      handleRightClick(item) {
      console.log('item', item);

      let id = item._id;
      const url = `/customer/${id}?index=0`; 
      window.open(url, '_blank');
    },

      

    resetLoadingState() {
      this.stateTroubleShoot = {
        oltRx: null,
        onuRx: null,
        signal: null,
        onuIP: null,
        upTime: null,
        status: null,
        result: null
      },

        this.loadingStateTroubleShoot = {
          oltRx: true,
          onuRx: true,
          signal: true,
          onuIP: true,
          upTime: true,
          status: true,
          result: true
        },

        this.StateTroubleShootValue = {
          oltRx: null,
          onuRx: null,
          onuSignal: null,
          onuIP: null,
          onuUptime: null,
          onuState: null,
          result: null,
          code: null,
          checkingPayment: null,
          ticketId: null,
          stage_id: null
        }

    },

    tambahTicket() {
      this.$refs['modal-tambah-ticket'].show()
    },
    hideModaltambahTicket() {
      this.$refs['modal-tambah-ticket'].hide()


      this.choice.customerProduct = {
        code: '',
        label: 'Pilih'
      },
        this.choice.category = {
          code: '',
          label: 'Pilih'
        },
        this.choice.priority = {
          code: '',
          label: 'Pilih'
        }

    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      if (filteredItems.length === 0 || filteredItems === null) {
        // Jika tidak ada data yang ditemukan, lakukan tindakan yang diperlukan
        console.warn('Tidak ada data yang ditemukan.');
        // Contoh: Set totalRows ke 0 dan tetap di halaman 1
        this.totalRows = 0;
        this.currentPage = 1;
      } else {
        // Jika ada data yang ditemukan, update jumlah totalRows dan reset currentPage
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      }
    },

    async getDataPetugas() {
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'analaytic-dashboard-ticket?petugas=true').then(response => {
        this.holderIdPetugas = response.data.idPetugas
        return this.optionsPetugas = response.data.dataPetugas
      })


    },
    updateAssigementSeleceted(index) {
      this.holderSelectedIdPetugas.splice(index, 1)
      this.selectedPetugas.splice(index, 1)
      this.selectedPetugasCount--
    },

    async filterTicket() {
      await this.$nextTick();
     this.applyFilter()
     
    

      console.log('afterclicket ',this.advFilterParamOn );
     
      if (this.advFilterParamOn === false) {
        this.customerData = null
        this.paginationCountSkip = 0
        this.currentPage = 1
      }

      this.advFilterParamOn = true
      let query = ""
      
      if (this.advFilterQuery.clientGrup !== null) {
        query = query + "&clientGroup=" + this.advFilterQuery.clientGrup
      }
      if (this.advFilterQuery.product !== null) {
        query = query + "&productId=" + this.advFilterQuery.product
      }

      if (this.isCheckedRange) {
        let date = moment(this.advFilterQuery.tanggalRegis).format("MM-DD-YYYY")
        let dateEnd = moment(this.advFilterQuery.dateEnd).format("MM-DD-YYYY")
        query = query + "&createdAt[$gte]=" + date + "&createdAt[$lte]=" + dateEnd
      }
      else {
        if (this.advFilterQuery.tanggalRegis !== null) {
          let date = moment(this.advFilterQuery.tanggalRegis).format("MM-DD-YYYY")
          let dateEnd = moment(this.advFilterQuery.tanggalRegis).format("MM-DD-YYYY")
          query = query + "&createdAt[$gte]=" + date + "&createdAt[$lte]=" + dateEnd
        }
      }

      if (this.advFilterQuery.statusProduk !== null) {
        query = query + "&productStatus=" + this.advFilterQuery.statusProduk
      }


      
      const isValid = Object.values(this.advFilterQuery).some(value => value !== null);

      console.log('isValid', isValid);
      if (isValid) {
        await this.fetchCustomerData(query)
        this.saveFilterData()
      }
      else {
        Swal.fire("Error!", "Silahkan Pilih Filter Yang ingin Diterapkan", "info");
      }

      this.filterRequest = false
      console.log('this.filterRequest',this.filterRequest);
      
      // if (this.customerData == null) {

      //   await axios.get(process.env.VUE_APP_URL_FEATHERS + "customer-list?$skip=" + this.paginationCountSkip + "&$limit=" + pageCount + query + query).then(response => {

      //     this.customerData = response.data

      //     return this.customerData
      //   })
      // }
      // else {

      //   await axios.get(process.env.VUE_APP_URL_FEATHERS + "customer-list?$skip=" + this.paginationCountSkip + "&$limit=" + pageCount + query + query).then(response => {

      //     this.customerData = response.data

      //     return this.customerData
      //   })

      // }


    },
    applyFilter() {
      // Simpan filter ke localStorage sebelum fetch data
      sessionStorage.setItem("savedFilters", JSON.stringify(this.advFilterQuery));
      
    },
    saveFilterData(){
      console.log('dreamer',this.customerData);
      sessionStorage.setItem("savedDataFilters", JSON.stringify(this.customerData));
      sessionStorage.setItem("savedtotalRowsCount", JSON.stringify(this.totalRowsCount));
      sessionStorage.setItem("savedCurrentPage", JSON.stringify(this.currentPage));
      sessionStorage.setItem("savedSkippedData", JSON.stringify(this.paginationCountSkip));
      sessionStorage.setItem("savedAccordion", JSON.stringify(this.compoAcordion));
    },

    async fetchCustomerData(query) {
      console.log('query', query, this.paginationCountSkip);
      let pageCount = this.perPage + 1;
      let dataHolderCounting = [];
      let loader = this.$loading.show({
        loader: 'spinner',
        color: '#ff5024',
      });

      try {
        const response = await axios.get(
          process.env.VUE_APP_URL_FEATHERS +
          "customer-list?newTable=true&filter=" +
          query +
          "&$skip=" +
          this.paginationCountSkip +
          "&$limit=" +
          pageCount
        );
        loader.hide();
        dataHolderCounting = response.data.data;
        this.totalRowsCount =  response.data.total
        
       

        if (dataHolderCounting.length >= 11) {
          this.paginationCountSkip += pageCount;
          this.customerData = this.customerData
            ? this.customerData.concat(dataHolderCounting)
            : dataHolderCounting;
          loader.hide();
          console.log('this.paginationCountSkip1', this.paginationCountSkip);
          this.jumlahData = this.customerData.length
          return this.customerData;
        }

        while (dataHolderCounting.length < 11) {
          this.paginationCountSkip += pageCount;

          const response1 = await axios.get(
          process.env.VUE_APP_URL_FEATHERS +
          "customer-list?newTable=true&filter=" +
          query +
          "&$skip=" +
          this.paginationCountSkip +
          "&$limit=" +
          pageCount
        );

          console.log('Data tambahan:', response1.data);

          dataHolderCounting = dataHolderCounting.concat(response1.data);

          if (dataHolderCounting.length >= 11) {
            this.customerData = this.customerData
              ? this.customerData.concat(dataHolderCounting)
              : dataHolderCounting;

              this.jumlahData = this.customerData.length
            console.log('this.customerData:', this.customerData);
            break;
          }
        }
        this.jumlahData = this.customerData.length
        loader.hide();
        
        return this.customerData;
      } catch (error) {
        console.error('Terjadi kesalahan saat mengambil data:', error);
        loader.hide();
        return [];
      }
    },
    async resetFilter() {

      this.advFilterQuery = {
        clientGrup: null,
        product: null,
        tanggalRegis: null,
        dateEnd: null,
        statusProduk: null
      },

   
        this.compoAcordion = {
          acordion1: false,
          acordion2: false,
          acordion3: false,
          acordion4: false,
          acordion5: false,
        }
      this.advFilterParamOn = false
      sessionStorage.removeItem("savedDataFilters");
      sessionStorage.removeItem("savedFilters");
      sessionStorage.removeItem("savedtotalRowsCount");
      await this.getCustomerData()
    },
    async skipCount() {

      this.paginationCountSkip = 0

      this.paginationCountSkip = (this.currentPage) * this.perPage

      return this.paginationCountSkip
    },

    regexSearch: _.debounce(async function () {
      let loader = this.$loading.show({
        loader: 'spinner',
        color: '#ff5024'
      });
      let query = "";
      this.advFilterParamOn = false;
      this.paginationCountSkip = 0
      if (this.regexHolder.length >= 3) {
        query = query + "&regex=" + this.regexHolder;
        
        let pageCount = this.perPage + 1;
        let url =
          "customer-list?newTable=true&$skip=" +
          this.paginationCountSkip +
          "&$limit=" +
          pageCount +
          query;

        console.log('url',url);
        
        this.customerData = await axios
          .get(process.env.VUE_APP_URL_FEATHERS + url)
          .then((response) => {
            console.log('response',response.data);
            this.regexIdentifierSearch = true;
            this.totalRowsCount = response.data.total
            return response.data.data;
          });

          console.log('this.customerData',this.customerData);
          
      } else if (
        this.regexHolder.length < 3 &&
        this.regexIdentifierSearch == true
      ) {
        await this.getCustomerData();
        this.regexIdentifierSearch = false;
      }
      loader.hide()
    }, 200),
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <b-card>
        <div class="row ">
          <div class="col-md-12 col-md-12">
            <div class="row">


              <div :class="isSidebarOpen ? 'col-md-9' : 'col-md-12' ">
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title"></h4>
                      <div id="tickets-table_length" class="dataTables_length">
                        <div class="row">
                          <div class="col-md-6">
                            <label class="d-inline-flex align-items-center fw-normal">
                              Show&nbsp;
                            </label>
                            <b-form-select v-model="perPage" :options="pageOptions" size="sm"
                              class="mr-6"></b-form-select>&nbsp;entries &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </div>
                          <div class="col-md-6">
                           
                            <b-form-group label-for="filter-input" label-cols-sm="6" label-align-sm="right"
                              label-size="sm" class="mb-0 ">
                              
                              <b-input-group size="sm" class="mb-2 flaot-end">
                               
                                <b-form-input id="filter-input" ref="filterRegex" v-model="regexHolder" type="text"
                                  placeholder="Nama / No HP / Order Id" size="sm" @keyup="regexSearch()"></b-form-input>
                                <b-input-group-append>
                                  <b-button  :disabled="!regexHolder"
                                    @click="getCustomerData" style="margin-right: 15px;">Clear</b-button>
                                    <b-button class="mr-3 float-end" v-if="!isSidebarOpen" variant="primary" @click="isSidebarOpen = !isSidebarOpen, showFilters = !showFilters , toggleSidebar"
                                :style="{ width: isSidebarOpen ? '372px' : 'auto' }">
                                  <i class="mdi mdi-filter-outline"></i> Filter
                                </b-button>
                                </b-input-group-append>

                                
                              </b-input-group>
                            </b-form-group>
                          </div>
                        </div>
                      </div>

                      <b-table head-variant="light" :items="customerData" :fields="fieldsDatakategori" show-empty
                        striped outlined hover class="mb-1 table-hover pointer-cursor" responsive="md" :per-page="perPage" 
                        thead-tr-class="bg-secondary" tbody-tr-class="custom-border" :current-page="currentPage" 
                        :filter="filter" :filter-included-fields="filterOn" :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered"
                        @row-clicked="handleRowClicked"   >

                        <template #cell(products)="data">

                          <div v-if="data.item.products && data.item.products.length > 0" v-html="formatStatusProducts(data.item.products)"
                            @click.stop="handleBadgeClick(data.item.products, $event, data.index, data)"></div>
                            <div v-else>
                            Tidak ada products
                          </div>
                        </template>

                        <template #cell(orderId)="data">

                          <div v-if="data.item.products && data.item.products.length > 0" 
                            v-html="formatStatusOrderId(data.item.products)"
                            @click.stop="handleBadgeClick(data.item.products, $event, data.index, data)"></div>
                            <div v-else>
                            -
                          </div>
                        </template>

                        <template #cell(status)="data">
                          <div v-html="formatStatusInvoice(data.item.products)"
                            @click.stop=""></div>
                        </template>
                        <template #cell(name)="data">
                          <a
                            :href="`/customer/${data.item._id}?index=0`"
                            class="text-primary fw-bold font-size-14"
                            style="cursor: pointer; text-decoration: none;"
                          >
                            {{ data.item.name }}
                          </a>
                        </template>


                        <template #cell(ticket)="data">
                          <span class="badge bg-info me-1"
                            :class="{ 'bg-secondary': formatStatusTicket(data.item.ticket).disabled }"
                            :disabled="formatStatusTicket(data.item.ticket).disabled"
                            @click.stop="handleBadgeClickTicket(data.item, data.index)">
                            Total: {{ formatStatusTicket(data.item.ticket).countTicket }}
                          </span>
                        </template>

                        <template #cell(phonenumber)="data">
                          {{ maskPhoneNumber(data.item.phonenumber) }}
                        </template>


                        <template #empty>
                          <h5>Tidak ada data</h5>
                        </template>
                        <template #table-busy>
                          <div class="text-center  my-2">
                            <b-spinner class="xs-1" variant="primary"></b-spinner>
                            <strong>Memuat data...</strong>
                          </div>
                        </template>

                        <template>

                        </template>

                      </b-table>
                      <div class="dataTables_paginate paging_simple_numbers float-end mt-2">
                        <ul class="pagination pagination-rounded">
                          <!-- pagination -->
                            <p class="mb-0 mt-2">
                            {{ startRow }}-{{ endRow }} of {{ totalRowsCount }}
                          </p>
                          <b-pagination v-model="currentPage" 
                                        :total-rows="totalRowsCount" 
                                        :per-page="perPage" 
                                        :hide-goto-end-buttons="false" 
                                        limit="1"
                                        size="sm"
                                        first-text="⏮"
                                        prev-text="⏪"
                                        next-text="⏩"
                                        last-text="⏭"
                                        @page-click="paginateRequest">
                          </b-pagination>

                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div :class="isSidebarOpen ? 'col-md-3' : 'col-md-auto'" >
                <b-button v-if="isSidebarOpen" variant="primary" @click="isSidebarOpen = !isSidebarOpen, showFilters = !showFilters , toggleSidebar"
                                :style="{ width: isSidebarOpen ? '372px' : 'auto' }">
                                  <i class="mdi mdi-filter-outline"></i> Filter
                                </b-button>
                <b-sidebar id="sidebar-right" right shadow transition="slide-right" no-header :visible="isSidebarOpen" ref="sidebar">
                  <b-card class="md">
                    <div class="d-flex justify-content-between align-items-center">
                      <h4 class="card-title">Filter Pelanggan</h4>

                    </div>

                    <!-- Accordion Filter -->
                    <b-collapse v-model="showFilters">
                      <p class="card-title-desc">Terapkan pencarian pada field di bawah ini</p>
                      <div class="custom-accordion">

                        <!-- Client Group -->
                        <div class="card border-0 shadow-sm mb-3">
                          <a href="javascript:void(0);" class="d-flex align-items-center text-dark p-3"
                            v-b-toggle.accordion-client-group style="text-decoration: none;">
                            <div class="icon-container bg-light rounded-circle me-3">
                              <i class="uil uil-calender text-primary h4 mb-0"></i>
                            </div>
                            <div class="flex-grow-1">
                              <h4 class="font-weight-bold mb-1" style="font-size: 14px;">Client Group</h4>
                              <p class="text-muted mb-0" style="font-size: 12px;">Pilih grup klien yang ingin Anda
                                filter
                              </p>
                            </div>
                            <i class="mdi mdi-chevron-down accor-down-icon font-size-24 text-primary"></i>
                          </a>
                          <b-collapse id="accordion-client-group" v-model="compoAcordion.acordion1">
                            <div class="p-3 border-top">
                              <b-form-checkbox-group v-model="advFilterQuery.clientGrup"
                                :options="advFilterHolder.advFilterClientGroup">
                              </b-form-checkbox-group>
                            </div>
                          </b-collapse>
                        </div>

                        <!-- Product -->
                        <div class="card border-0 shadow-sm mb-3">
                          <a href="javascript:void(0);" class="d-flex align-items-center text-dark p-3"
                            v-b-toggle.accordion-product style="text-decoration: none;">
                            <div class="icon-container bg-light rounded-circle me-3">
                              <i class="bx bx-map-pin text-primary h4 mb-0"></i>
                            </div>
                            <div class="flex-grow-1">
                              <h4 class="font-weight-bold mb-1" style="font-size: 14px;">Product</h4>
                              <p class="text-muted mb-0" style="font-size: 12px;">Pilih produk yang ingin Anda filter
                              </p>
                            </div>
                            <i class="mdi mdi-chevron-down accor-down-icon font-size-24 text-primary"></i>
                          </a>
                          <b-collapse id="accordion-product" v-model="compoAcordion.acordion2">
                            <div class="p-3 border-top">
                              <b-form-checkbox-group v-model="advFilterQuery.product"
                                :options="advFilterHolder.advFilterProduct" style="font-size: 10px;">
                              </b-form-checkbox-group>
                            </div>
                          </b-collapse>
                        </div>

                        <!-- Tanggal Registrasi -->
                        <div class="card border-0 shadow-sm mb-3">
                          <a href="javascript:void(0);" class="d-flex align-items-center text-dark p-3"
                            v-b-toggle.checkout-iseng-collapse style="text-decoration: none;">
                            <div class="icon-container bg-light rounded-circle me-3">
                              <i class="uil uil-calender text-primary h4 mb-0"></i>
                            </div>
                            <div class="flex-grow-1">
                              <h4 class="font-weight-bold mb-1" style="font-size: 14px;">Tanggal Registrasi</h4>
                              <p class="text-muted mb-0" style="font-size: 12px;">Atur rentang tanggal registrasi</p>
                            </div>
                            <i class="mdi mdi-chevron-down accor-down-icon font-size-24 text-primary"></i>
                          </a>
                          <!-- Collapsible Content -->
                          <b-collapse id="checkout-iseng-collapse" v-model="compoAcordion.acordion3">
                            <div class="p-3 border-top">
                              <!-- Range Date Card -->
                              <div class="card border-light shadow-sm p-3 mb-3">
                                <div class="d-flex justify-content-between align-items-center mb-2">
                                  <h5 class="font-weight-bold mb-0" style="font-size: 14px;">Rentang Tanggal</h5>
                                  <switches v-model="isCheckedRange" type-bold="true"
                                    :color="isCheckedRange ? 'success' : 'danger'" class="custom-switch"
                                    style="margin-top: -5px;top: 10px;"></switches>
                                </div>
                                <p class="text-muted" style="font-size: 12px;">
                                  Aktifkan rentang tanggal untuk menyaring data lebih spesifik.
                                </p>
                                <div class="row g-2">
                                  <div class="col-12">
                                    <date-picker v-model="advFilterQuery.tanggalRegis" :default-value="new Date()"
                                      type="date" :placeholder="isCheckedRange ? 'Awal Tanggal' : 'Pilih Tanggal'"
                                      class="form-control"></date-picker>
                                  </div>
                                  <div class="col-12" v-if="isCheckedRange">
                                    <date-picker v-model="advFilterQuery.dateEnd" :default-value="new Date()"
                                      type="date" placeholder="Akhir Tanggal" class="form-control"></date-picker>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-collapse>
                        </div>

                        <!-- Status Product -->
                        <div class="card border-0 shadow-sm mb-3">
                          <a href="javascript:void(0);" class="d-flex align-items-center text-dark p-3"
                            v-b-toggle.accordion-status style="text-decoration: none;">
                            <div class="icon-container bg-light rounded-circle me-3">
                              <i class="uil uil-pricetag-alt text-primary h4 mb-0"></i>
                            </div>
                            <div class="flex-grow-1">
                              <h4 class="font-weight-bold mb-1" style="font-size: 14px;">Status Product</h4>
                              <p class="text-muted mb-0" style="font-size: 12px;">Filter berdasarkan status</p>
                            </div>
                            <i class="mdi mdi-chevron-down accor-down-icon font-size-24 text-primary"></i>
                          </a>
                          <b-collapse id="accordion-status" v-model="compoAcordion.acordion4">
                            <div class="p-3 border-top">
                              <b-form-checkbox-group v-model="advFilterQuery.statusProduk">
                                <b-form-checkbox value="active">Active</b-form-checkbox>
                                <b-form-checkbox value="suspend">Suspend</b-form-checkbox>
                              </b-form-checkbox-group>
                            </div>
                          </b-collapse>
                        </div>

                        <!-- Tombol Terapkan dan Reset -->
                        <div class="d-flex gap-3">
                          <b-button variant="success" class="w-100" @click="filterTicket() , advFilterParamOn = false">
                            <i class="mdi mdi-magnify me-2"></i> Terapkan
                          </b-button>
                          <b-button variant="warning" class="w-100" @click="resetFilter()">
                            <i class="mdi mdi-restart me-2"></i> Reset
                          </b-button>
                        </div>

                      </div>
                    </b-collapse>
                  </b-card>


                </b-sidebar>
              </div>

              <!-- Pindahkan seluruh konten ke dalam b-sidebar -->

            </div>
          </div>
        </div>


      </b-card>
      <!-- end col -->
    </div>
    <!-- end row -->
    
    <b-modal id="modal" ref="modal-invoice" centered title="Invoice 5 Bulan Terakhir"
  title-class="font-18 text-primary fw-bold" size="lg" hide-footer>
  
  <b-card class="border-0 shadow-lg p-1 mb-0">
    <div v-for="(product, productIndex) in holderDataStatusInvoice" 
         :key="product.orderId" 
         class="mb-1">
      
      <!-- Kartu Utama -->
      <div class="card shadow-lg border-0 rounded-lg overflow-hidden mb-0">
        
        <!-- Header Produk -->
        <div class="card-header bg-gradient text-white d-flex justify-content-between align-items-center">
          <h5 class="m-0 text-uppercase fw-bold">{{ product.productsName || 'N/A' }}</h5>
          <span class="text-light">Order ID: <strong>{{ product.orderId || 'N/A' }}</strong></span>
        </div>

        <!-- Body Produk -->
        <div class="card-body">
          <ul class="list-group list-group-flush">
            <li class="list-group-item d-flex justify-content-between align-items-center">
              <span class="text-muted">Invoice Terbaru</span>
              <strong class="text-dark">{{ product.bulan || 'N/A' }}</strong>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              <span class="text-muted">Order ID</span>
              <strong class="text-dark">{{ product.orderId || 'N/A' }}</strong>
            </li>
          </ul>
        </div>
      </div>

      
      <div class="card mt-2 shadow-sm border-0 rounded-lg overflow-hidden">
        
        
        <div class="card-header bg-primary text-white d-flex justify-content-between align-items-center"
             @click="toggleAccordion(productIndex)" 
             style="cursor: pointer; transition: all 0.3s ease-in-out;">
          <span class="fw-bold">Invoices Details</span>
          <i :class="accordionState[productIndex] ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
        </div>

        <transition name="fade">
          <div class="card-body p-0" v-show="accordionState[productIndex]">
            <div class="row font-weight-bold text-center py-2 border-bottom bg-light">
              <div class="col-3">Periode</div>
              <div class="col-3">Status</div>
              <div class="col-3">Nominal</div>
              <div class="col-3">Paid At</div>
            </div>

            <div v-for="invoice in product.invoices" :key="invoice._id"
                 class="row align-items-center py-2 px-3 border-bottom text-center">
              <div class="col-3"><strong>{{ invoice.bulan || 'N/A' }}</strong></div>
              <div class="col-3">
                <span class="badge px-3 py-2 rounded-pill text-white" 
                      :class="{
                        'bg-success': invoice.status === 'settlement',
                        'bg-warning': invoice.status === 'pending',
                        'bg-danger': !invoice.status || (invoice.status !== 'settlement' && invoice.status !== 'pending'),
                      }">
                  {{ invoice.status || 'N/A' }}
                </span>
              </div>
              <div class="col-3 text-primary fw-bold">
                {{ invoice.grossAmount ? 'Rp ' + new Intl.NumberFormat('id-ID').format(invoice.grossAmount) : '0' }}
              </div>
              <div class="col-3">{{ invoice.paidAt || 'N/A' }}</div>
            </div>

            <b-button class="float-end m-3 shadow-sm fw-bold" variant="info" size="md" @click="viewInvoice(product)">
              Lihat Semua
            </b-button>
          </div>
        </transition>
      </div>

    </div>
  </b-card>
</b-modal>



    <b-modal id="modal-center" ref="modal-ticket" centered title="5 Ticket Terakhir"
      title-class="font-18 text-center font-weight-bold" size="md">
      <div class="p-3">
        <!-- Card Wrapper -->
        <div class="card shadow-lg rounded-3 border-0">
          <!-- Table Header -->
          <div class="row card-header bg-gradient-info text-white font-weight-bold align-items-center pe-0 py-3">
            <div class="col-4 text-left">No Ticket</div>
            <div class="col-4 text-left">Pengerjaan</div>
            <div class="col-2 text-center">Status</div>
            <div class="col-2 text-center">Detail</div>
          </div>

          <!-- Table Rows -->
          <transition-group name="fade" tag="div">
            <div v-for="(ticket, index) in holderDataStatusTicket" :key="ticket.noTicket" :class="[
              'row align-items-center py-3 px-3 border-bottom',
              index % 2 === 0 ? 'bg-light' : 'bg-white'
            ]">
              <!-- No Ticket -->
              <div class="col-4 font-weight-bold text-primary text-truncate">
                {{ ticket.noTicket || 'N/A' }}
              </div>
              <!-- Pengerjaan -->
              <div class="col-4 text-left text-secondary">
                {{ getTicketStageText(ticket.stage) }}
              </div>
              <!-- Status -->
              <div class="col-2 text-center">
                <span class="badge px-3 py-2" :class="{
                  'bg-success': ticket.status === 'closed',
                  'bg-danger': ticket.status === 'open'
                }">
                  {{ ticket.status || 'N/A' }}
                </span>
              </div>
              <!-- Detail -->
              <div class="col-2 text-center">
                <b-button variant="outline-primary" size="sm" class="btn-detail" @click="showTicketDetail(ticket)">
                  Detail
                </b-button>
              </div>
            </div>
          </transition-group>
        </div>
      </div>

      <template #modal-footer="{ hide }">
        <div class="d-flex justify-content-between w-100">
          <b-button size="sm" variant="outline-danger" @click="hide">
            Close
          </b-button>
          <b-button size="sm" variant="info" class="btn-animate" @click="viewTicket">
            Lihat Semua
          </b-button>
        </div>
      </template>
    </b-modal>




  </Layout>
</template>

<style>
img {
  height: auto;
  max-width: 2.5rem;
  margin-right: 1rem;
}

.d-center {
  display: flex;
  align-items: center;
}

.selected img {
  width: auto;
  max-height: 23px;
  margin-right: 0.5rem;
}

.v-select .dropdown li {
  border-bottom: 1px solid rgba(112, 128, 144, 0.1);
}

.v-select .dropdown li:last-child {
  border-bottom: none;
}

.v-select .dropdown li a {
  padding: 10px 20px;
  width: 100%;
  font-size: 1.25em;
  color: #3c3c3c;
}

.v-select .dropdown-menu .active>a {
  color: #fff;
}

.custom-icon {
  font-size: 1.25rem;
  margin-top: 0.313rem;
  padding-left: 0.313rem;

}

.input-container {
  position: relative;
}

.loading-overlay {
  position: absolute;
  width: 1.163rem;
  height: 1.163rem;
  border-radius: 50%;
  border: 3px solid #ccc;
  border-top-color: #333;
  animation: spin 1s infinite;
}

.row-closed {
  background-color: #d4edda;
}

.row-open {
  background-color: #f8d7da;
}

.priority-high {
  border-left: 5px solid #dc3545;
}

.priority-low {
  border-left: 5px solid #ffc107;
}


.boarder-notif-true {
  background: transparent;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: rgb(241, 79, 79);
  border-radius: 0.1rem;
  transition: border-color 3s ease;
}

.boarder-notif-false {
  background: transparent;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: rgb(79, 241, 95);
  border-radius: 0.1rem;
  transition: border-color 3s ease;
}

.fade-in-enter-active,
.fade-in-leave-active {
  transition: opacity 1s ease;
}

.fade-in-enter,
.fade-in-leave-to {
  opacity: 0;
}

.custom-switch {
  transform: scale(0.65);
  /* Sesuaikan nilai skala sesuai kebutuhan */
  position: relative;
  width: 60px;
  /* Sesuaikan lebar switch */
  height: 30px;
  /* Sesuaikan tinggi switch */
}

.btn-detail {
  transition: all 0.3s ease-in-out;
}

.btn-detail:hover {
  transform: scale(1.1);
}


.btn-animate {
  transition: background 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.btn-animate:hover {
  background: linear-gradient(90deg, #17a2b8, #138496);
  transform: scale(1.1);
}


.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.full-width-button {
  width: 300px; 
  transition: width 0.3s ease;
}
.b-pagination .page-item.active {
  display: none;
}

.custom-border>td {
  border-bottom: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
}



</style>